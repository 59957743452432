import store from '@/store'
// import router from '@/router'
// import Swal from 'sweetalert2'
import PermissionChecker from '@/utils/PermissionChecker';


/**
 * middleware: permission
 *
 * 檢查權限是否符合
 * 可以透過 route 裡面的 meta.permissions 來控制允許哪些權限進入
 *
 * 1. 如果 meta.permissions 留空陣列則代表無限制
 * 2. 如果 meta.permissions 有兩個以上 permission，則代表兩者皆需擁有才可進入
 */
export default async function permission({ next, to }) {
  const route = to.matched.slice().reverse().find(route => route.meta.permissions !== undefined)

  // 檢查是否符合權限
  if (route) {
    const requiredPermissions = route.meta.permissions;

    if ((new PermissionChecker).check(requiredPermissions)) {
      return next();
    } else {
      // await Swal.fire({
      //   title: '錯誤',
      //   type: 'error',
      //   text: '您沒有訪問此功能的權限，請確認身份別'
      // })
      return next({name: 'NoPermission403', params: {org_code: store.state.general.currentOrgCode}})
    }
  }

  return next()
}
