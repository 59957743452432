import organization from "@/apis/organization";
import branch from "@/apis/branch";
import router from '@/router'
import notificationApi from "@/apis/notification";
import Swal from "sweetalert2";
import { SIDEBAR_THEMES } from "@/consts";

export default {
  namespaced: true,
  state: {
    currentOrgCode: null,
    organization: null,
    organizationThemeConfig: null,
    myOrganizations: null,
    myBranches: null,
    currentBranch: null,
    unreadNotificationCount: 0,
    newestNotification: null,
    mainViewExpand: false,
    sidebarTheme: SIDEBAR_THEMES.THEME_1,
    dashboardModule: false,
    defaultMerchantType: 'LINE'
  },
  getters: {
    branches: (state) => state.branches,
    myBranches (state) {
      return state.myBranches.filter(branch => branch.org_id == state.organization.id)
        .sort((a, b) => {
          if (a.level == b.level) {
            return a.order - b.order
          }
          return a.level - b.level
        });
    },
    organization: (state) => state.organization,
    role (state) {
      const organization = state.myOrganizations.find(organization => state.organization.id === organization.id)

      if (organization && organization.pivot.role) {
        return organization.pivot.role
      } else {
        return 'unknown'
      }
    },
    permissions (state) {
      const organization = state.myOrganizations.find(organization => state.organization.id === organization.id);

      if (organization && organization.permissions) {
        return organization.permissions;
      } else {
        return [];
      }
    },
    currentBranch: (state) => state.currentBranch,
    branchOptions: (state) => {
      return state.myBranches
        .filter(branch => branch.org_id === state.organization.id)
        .sort((a, b) => a.level - b.level)
        .sort((a, b) => {
          // if "level" is the same, order by "order"
          if (a.level === b.level) {
            return a.order - b.order
          }
        })
        .map(branch => {
          let branchName = `${branch.branch_code} ${branch.name}`
          if (branch.level === 2) {
            branchName = `- ${branch.branch_code} ${branch.name}`
          }
          if (branch.level === 3) {
            branchName = `- - ${branch.branch_code} ${branch.name}`
          }

          if (branch.org_id === state.organization.id) {
            return {
              value: branch.id,
              text: branchName
            }
          }
        })
    },
    unreadNotificationCount: (state) => state.unreadNotificationCount,
    newestNotification: (state) => state.newestNotification,
    mainViewExpand: (state) => state.sidebarWidth,
    sidebarTheme: (state) => state.sidebarTheme,
    dashboardModule: ({ dashboardModule }) => dashboardModule,
    defaultMerchantType: (state) => state.defaultMerchantType
  },
  mutations: {
    SET_CURRENT_ORG_CODE (state, org_code) {
      state.currentOrgCode = org_code
    },
    SET_ORG (state, organization) {
      state.organization = organization
    },
    SET_MY_BRANCHES (state, branches) {
      state.myBranches = branches
    },
    SET_CURRENT_BRANCH (state, currentBranch) {
      state.currentBranch = currentBranch
    },
    SET_ORGANIZATION_THEME_CONFIG (state, themeConfig) {
      state.organizationThemeConfig = themeConfig
    },
    SET_MY_ORGANIZATIONS (state, organizations) {
      state.myOrganizations = organizations
    },
    SET_UNREAD_NOTIFICATION_COUNT (state, count) {
      state.unreadNotificationCount = count
    },
    SET_NEWEST_NOTIFICATION (state, notification) {
      state.newestNotification = notification
    },
    SET_MAINVIEW_EXPAND(state, expand) {
      state.mainViewExpand = expand;
    },
    SET_SIDEBAR_THEME(state, theme) {
      state.sidebarTheme = theme;
    },
    SET_DASHBOARD_MODULE(state, module) {
      state.dashboardModule = module;
    },
    SET_DEFAULT_MERCHANT_TYPE(state, type) {
      state.defaultMerchantType = type;
    }
  },
  actions: {
    async fetchOrganization (context, orgCode) {
      try {
        let response = await organization.get(orgCode)

        if (response.status === 200 && response.data.data !== null) {
          context.commit('SET_ORG', response.data.data)

          // 如果目前訪問的 organization 不是 current_org，就變更 current_org
          if (context.rootState.auth.user) {
            if (context.rootState.auth.user.current_org_id && response.data.data.id !== context.rootState.auth.user.current_org_id) {
              context.dispatch('setCurrentOrg', response.data.data.id);
            }
          }
        }
      } catch (e) {
        await Swal.fire({
          title: '錯誤',
          text: '查無資料',
          type: 'error',
        })

        router.push('/')
      }
    },
    async fetchBranches (context) {
      let response = await branch.getMyBranches()
      if (response.status === 200 && response.data.data !== null) {
        context.commit('SET_MY_BRANCHES', response.data.data.branches)
        context.commit('SET_CURRENT_BRANCH', response.data.data.current_branch)
      } else {
        alert('取得分店資料失敗')
      }
    },
    async fetchMyOrganizations (context) {
      let response = await organization.getMyOrganizations()
      if (response.status === 200 && response.data.data !== null) {
        context.commit('SET_MY_ORGANIZATIONS', response.data.data.organizations)
        // 還沒實作切換 Organizations 的機制，這 part 先拿掉
        // context.commit('SET_ORGANIZATION', response.data.data.current_organization)
      } else {
        alert('取得組織資料失敗')
      }
    },
    async setCurrentBranch(context, currentBranch) {
      let response = await branch.setCurrentBranch(currentBranch)

      if (response.status === 200 && response.data.status === 'success') {
        context.commit('SET_CURRENT_BRANCH', response.data.data.current_branch)
      } else {
        alert('選擇分店失敗')
      }
    },
    reset (context) {
      context.commit('SET_MY_BRANCHES', null)
      context.commit('SET_ORG', null)
      context.commit('SET_CURRENT_BRANCH', null)
      context.commit('SET_ORGANIZATION_THEME_CONFIG', null)
      context.commit('SET_MY_ORGANIZATIONS', null)
      context.commit('SET_DASHBOARD_MODULE', false)
    },
    resetDashboardModule (context) {
      context.commit('SET_DASHBOARD_MODULE', false)
    },
    async fetchOrganizationThemeConfig (context) {
      try {
        let response = await organization.getThemeConfig(context.state.organization.code)

        if (response.status === 200 && response.data.data !== null) {
          let data = response.data.data;
          context.commit('SET_ORGANIZATION_THEME_CONFIG', data);
        }
      } catch (e) {
        console.error('Get theme config failed')
      }
    },
    async setCurrentOrg (context, org_id) {
      let response = await organization.setCurrentOrg(org_id);

      if (response.status === 200 && response.data.data !== undefined) {
        context.commit('SET_ORG', response.data.data.current_org)
        await context.dispatch('fetchBranches');
        await context.dispatch('auth/fetchMe', null, { root: true });
      } else {
        Swal.fire({
          title: '錯誤',
          type: 'error',
          text: '切換組織失敗'
        })
      }
    },

    async getUnreadNotificationCount ({ commit, state }) {
      try {

       const { data } = await notificationApi.getBranchNotifications(
          state.currentBranch.id,
          {
            page: 1,
          }
       );

       commit('SET_UNREAD_NOTIFICATION_COUNT', data.unread_count);
       commit('SET_NEWEST_NOTIFICATION', data.data[0]);
     } catch (error) {
       alert("取得通知錯誤");
     }
    },
    async setMainViewExpand(context, expand) {
      context.commit('SET_MAINVIEW_EXPAND', expand);
    },
    async setSidebarTheme(context, theme) {
      context.commit("SET_SIDEBAR_THEME", theme);
    },
    async fetchDashboardModule(context) {
      if (context.state.dashboardModule) return;

      try {
        const { data } = await organization.getModuleConfig(context.state.organization.id, { module_code: 'dashboard' });
        context.commit('SET_DASHBOARD_MODULE', data);
        context.commit('SET_DEFAULT_MERCHANT_TYPE', (data?.default_merchant_type).toUpperCase() ?? 'LINE')
      } catch (error) {
        context.commit('SET_DASHBOARD_MODULE', {});
        if (error.status == 401) {
          this.fetchDashboardModule(context)
        } else {
          context.commit('SET_DASHBOARD_MODULE', {});
        }
      }
    },
  },
}
