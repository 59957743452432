import Client from '@/apis/liff/Coupon'
import { state, mutations, getters, actions } from './base'

const coreGroupBuyingState = Object.assign(state(), { shouldCheckFollowStatus: false })

export default {
  namespaced: true,
  state: coreGroupBuyingState,
  mutations: mutations(),
  getters: getters(),
  actions: {
    ...actions(Client),
  },
}
