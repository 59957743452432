import https from "./https";
import store from "@/store";

const module = {
  getModule(moduleCode) {
    const organization = store.state.liffGeneral.orgCode;
    return https.get(`${organization}/liff/modules/${moduleCode}`)
  },
  getModules(codes = []) {
    const organization = store.state.liffGeneral.orgCode;
    return https.get(`${organization}/liff/modules`, {
      params: {
        codes
      }
    })
  }
}

export default module
