const DEFAULT_FAVICON_HREF = '/favicon.ico'
const FAVICON_SIZE = 32
const BADGE_SIZE = 10
const BADGE_RATIO = BADGE_SIZE / FAVICON_SIZE

export default {
  namespaced: true,
  state: {
    originalFaviconHref: null,
  },
  actions: {
    showBadge ({ state }) {
      const favicon = document.querySelector('link[rel="icon"]')

      if (!favicon) {
        return
      }

      if (!state.originalFaviconHref) {
        state.originalFaviconHref = favicon.href || DEFAULT_FAVICON_HREF
      }

      const canvas = document.createElement('canvas')

      canvas.width = FAVICON_SIZE
      canvas.height = FAVICON_SIZE

      const context = canvas.getContext('2d')

      const img = document.createElement('img')

      img.src = state.originalFaviconHref

      img.onerror = () => {
        state.originalFaviconHref = DEFAULT_FAVICON_HREF
        img.src = state.originalFaviconHref
      }

      img.onload = () => {
        context.drawImage(img, 0, 0, FAVICON_SIZE, FAVICON_SIZE)
        context.beginPath()
        context.arc(
          FAVICON_SIZE - FAVICON_SIZE * BADGE_RATIO,
          FAVICON_SIZE * BADGE_RATIO,
          FAVICON_SIZE * BADGE_RATIO,
          0,
          2 * Math.PI
        )
        context.fillStyle = '#92D050'
        context.fill()

        favicon.href = canvas.toDataURL('image/x-icon')
      }
    },
    hideBadge ({ state }) {
      if (!state.originalFaviconHref) {
        return
      }

      const favicon = document.querySelector('link[rel="icon"]')

      if (!favicon) {
        return
      }

      favicon.href = state.originalFaviconHref
      state.originalFaviconHref = null
    }
  },
};
