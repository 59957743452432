<template>
  <div class="alert alert-danger validation-error-alert">
    <li v-for="(value, index) in validationErrors" :key="index">
      {{ value }}
    </li>
  </div>
</template>

<script>
export default {
  props: ["errors"],
  computed: {
    validationErrors() {
      let errors = Object.values(this.errors);
      errors = errors.flat();
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.validation-error-alert {
  padding: 8px 12px;
  font-size: 14px;
}
</style>
