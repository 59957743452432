const HTTP_STATUS_UNAUTHORIZED = 401
const HTTP_STATUS_UNPROCESSABLE_ENTITY = 422
const RETRY_LIMIT = 3

export default class Client {
  constructor ({ axios, baseURL, tokenProvider, liffInitializer }) {
    this.axios = axios
    this.baseURL = baseURL
    this.tokenProvider = tokenProvider
    this.liffInitializer = liffInitializer

    this.retryCount = 0
    this.client = this.createClient()

    this.injectBearerToken()
    this.setErrorHandler()
  }

  static async init ({ axios, baseURL, tokenProvider, liffInitializer }) {
    this.instance = new this({ axios, baseURL, tokenProvider, liffInitializer })
    await liffInitializer()
  }

  static getInstance () {
    if (!this.instance) {
      throw new Error('Must init first.')
    }

    return this.instance
  }

  createClient () {
    return this.axios.create({ baseURL: this.baseURL })
  }

  injectBearerToken () {
    this.client.interceptors.request.use(
      (config) => {
        const token = this.tokenProvider()

        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }

        return config
      },
      Promise.reject
    )
  }

  setErrorHandler () {
    this.client.interceptors.response.use(
      (response) => {
        this.retryCount = 0

        return response
      },
      (error) => {
        console.error(error);
        const response = error.response
        const status = response.status
        const message = this.getErrorMessage(response)

        console.error(`HTTP Error: [${status}] ${message}`)

        return Promise.reject({
          status,
          message,
          response: response.data,
        })
      }
    )
  }

  getErrorMessage (response) {
    if (response.status === HTTP_STATUS_UNPROCESSABLE_ENTITY) {
      return Object.values(response.data.message)[0][0]
    }

    return response.data.message
  }

  async request (method, url, data = {}) {
    try {
      console.debug(`request: ${method} ${url} ${JSON.stringify(data)}`)

      const response = await this.client.request({
        method,
        url,
        data,
      })

      console.debug(`response: ${JSON.stringify(response.data)}`)

      return response.data
    } catch (error) {
      return this.retry(error, method, url, data)
    }
  }

  async retry (error, method, url, data) {
    if (error.status !== HTTP_STATUS_UNAUTHORIZED || this.retryCount > RETRY_LIMIT) {
      this.retryCount = 0

      throw error
    }

    this.retryCount++

    console.debug(`retry ... ${this.retryCount}`)

    await this.liffInitializer()

    return this.request(method, url, data)
  }

  cleanQuery (query) {
    const result = {}

    for (const [key, value] of Object.entries(query)) {
      if (value === undefined || value === null) continue
      result[key] = value
    }

    return result
  }

  buildUrl (url, query) {
    if (Object.keys(query).length === 0) {
      return url
    }

    const params = new URLSearchParams(this.cleanQuery(query)).toString()

    return `${url}?${params}`
  }

  get (url, query = {}) {
    return this.request('get', this.buildUrl(url, query))
  }

  post (url, data = {}) {
    return this.request('post', url, data)
  }

  put (url, data = {}) {
    return this.request('put', url, data)
  }

  'delete' (url, data = {}) {
    return this.request('delete', url, data)
  }

  async getLiffInfo() {
    const response = await this.get('init')

    return response.data
  }

  async getCustomer() {
    const response = await this.get('customer')

    return response.data.customer
  }

  async sendVerifyCode({ mobileNumber }) {
    await this.post('send-verify-code', { mobile_phone: mobileNumber })
  }

  async checkMemberState({ mobileNumber, verifyCode }) {
    const response = await this.post(
      'check-verify-code',
      { mobile_phone: mobileNumber, verify_code: verifyCode }
    )

    return response.is_poya_member
  }

  async login({ mobileNumber, password }) {
    await this.post('login', { mobile_phone: mobileNumber, password })
  }

  async updatePassword({ mobileNumber, password }) {
    await this.post(
      'update-password',
      { mobile_phone: mobileNumber, password, password_confirmation: password }
    )
  }

  async updateCustomer({ email, mobile, name, county, district, zipcode, address }) {
    return await this.post(
      'customer',
      {
        email: email,
        mobile: mobile,
        'extra.name': name,
        'extra.county': county,
        'extra.district': district,
        'extra.zipcode': zipcode,
        'extra.address': address,
      }
    )
  }
}
