import Client from './Client'

export default class Chatroom extends Client {
  async getCustomer({ asStaff }) {
    const response = await this.get('customer', { as_staff: asStaff })

    return response.data.customer
  }

  async getChatrooms({ asStaff }) {
    const { data } = await this.get('', {as_staff: asStaff ? true : false})

    return data
  }

  async getChatroom({ chatroomId }) {
    const { data } = await this.get(`${chatroomId}`)

    return data
  }

  async getMessages({ chatroomId, cursor, count = 50 , asStaff}) {
    const { data, meta } = await this.get(`${chatroomId}/message`, { cursor, count, as_staff: asStaff ? true : false })

    return { messages: data, meta }
  }

  async sendMessage({ chatroomId, message, state, asStaff }) {
    const { data } = await this.post(`${chatroomId}/message`, { message, state, as_staff: asStaff ? true : false })

    return data
  }

  async markAsRead({ chatroomId, asStaff }) {
    await this.put(`${chatroomId}/read`, { as_staff: asStaff ? true : false })
  }

  async goToChat({ sourceType, sourceId, chatroomId = null }) {
    const { data } = await this.get('go-to-chat', {source_type: sourceType, source_id: sourceId, chatroom_id: chatroomId})

    return data;
  }

  async goToChatStaff({ sourceType, sourceId, skuId }) {
    const { data } = await this.get('go-to-chat-staff', {source_type: sourceType, source_id: sourceId, sku_id: skuId})

    return data;
  }

  async upload({ chatroomId, files }) {
    return this.post(`${chatroomId}/upload`, { files })
  }

  async markAsUploaded({ chatroomId, state, files }) {
    await this.put(`${chatroomId}/uploaded`, { state, files })
  }

  async getConfig() {
    const { data } = await this.get('config')

    return data
  }

  async generateMultipleUploadToken({ payload }) {
    return await this.get(`generate-multiple-upload-token`, payload);
  }
}
