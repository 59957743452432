import Checker from './Checker'

export default class RegisteredOnly extends Checker {
  check() {
    const registeredOnly = this.permissions.includes('registered')

    if (!registeredOnly) {
      return true
    }

    return !!this.customer?.registered_at
  }

  onFailure() {
    this.redirect({ name: 'LiffRegisterIndex' })
  }
}

