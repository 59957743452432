const KEY_NAME = 'LiffIntendedRoute'

function set (route) {
  window.localStorage.setItem(KEY_NAME, JSON.stringify(route))
}

function get (defaultValue) {
  const route = window.localStorage.getItem(KEY_NAME)

  if (route) {
    return JSON.parse(route)
  }

  return defaultValue
}

function clear () {
  window.localStorage.removeItem(KEY_NAME)
}

export default { set, get, clear }
