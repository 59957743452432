import store from '@/store'

/**
 * middleware: getOrganizationData
 *
 * 凡經過這層 Middleware，都必須先取得 Organization 的資料，以便畫面中使用，屬於非驗證類型 middleware
 */
export default async function getOrganizationData({ next, to }) {
  const org_code = to.params.org_code

  store.commit('general/SET_CURRENT_ORG_CODE', org_code)

  // 取得 Organization 的基本資訊
  if (! store.state.general.organization || store.state.general.organization.code !== org_code) {
    await store.dispatch('general/fetchOrganization', org_code)
  }

  // 讀取 Organization Theme Config，當 State 中沒有，且 organization 已存在時
  if (
    (! store.state.general.organizationThemeConfig && store.state.general.organization) ||
    (store.state.general.organizationThemeConfig && store.state.general.organizationThemeConfig.org_code !== org_code)
  ) {
    await store.dispatch('general/fetchOrganizationThemeConfig');
  }
  // DEBUG 先讓所有路由全開
  // await store.dispatch('general/fetchOrganizationThemeConfig');

  return next()
}
