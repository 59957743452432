
export default [
  // ** issue-2676 以後會統一為liff_xxx 單數 但有一些舊有網址還是雙數 這邊做轉導
  // point center
  {
    path: "/:org_code/liff/points/center",
    redirect: {
      name: "LiffPointsCenter",
    }
  },
  // point
  {
    path: "/:org_code/liff/points/exchange",
    redirect: {
      name: "LiffPointsExchange",
    }
  },
  {
    path: "/:org_code/liff/points/exchange_records",
    redirect: {
      name: "LiffPointsExchangeRecords",
    }
  },
  {
    path: "/:org_code/liff/points/records",
    redirect: {
      name: "LiffPointsRechargeRecords",
    }
  },
  {
    path: "/:org_code/liff/points/transfer",
    redirect: {
      name: "LiffPointsTransfer",
    }
  },
  // point tab
  {
    path:"/:org_code/liff/points/tab/exchange",
    redirect: {
      name: "LiffPointsExchangeTab",
    }
  },
  {
    path:"/:org_code/liff/points/tab/exchange_records",
    redirect: {
      name: "LiffPointsExchangeRecordsTab",
    }
  },
  {
    path:"/:org_code/liff/points/tab/records",
    redirect: {
      name: "LiffPointsRechargeRecordsTab",
    }
  },
  {
    path:"/:org_code/liff/points/tab/transfer",
    redirect: {
      name: "LiffPointsTransferTab",
    }
  },
  //order
  {
    path:"/:org_code/liff/orders/list",
    redirect: {
      name: "LiffOrderListIndex",
    }
  },
  {
    path:"/:org_code/liff/orders/list/:id",
    redirect: {
      name: "LiffOrderListShow",
    }
  },

  // issue-1094 網址重新命名，因為舊網址已公佈出去，所以要將舊網址重導向到新網址
  {
    path: "/:org_code/liff/loyalty-programs/exchange",
    redirect: {
      name: "LiffPointsExchange",
    }
  },
  {
    path: "/:org_code/liff/loyalty-programs/records",
    redirect: {
      name: "LiffPointsExchangeRecords",
    }
  },
  {
    path: "/:org_code/liff/loyalty-programs/recharges",
    redirect: {
      name: "LiffPointsRechargeRecords",
    }
  },
];
