import store from '@/store'
import router from '@/router'
import VConsole from 'vconsole'

/**
 * middleware: auth
 *
 * 只有登入過的用戶才能訪問
 */
export default function auth({ next, to }) {
  // 後台的訪問網址中，如果帶有 vconsole 參數，則開啟 vconsole debug tool.
  if (to.query['vconsole']) {
    new VConsole({ theme: 'dark' });
  }

  if (store.state.auth.isLogin) {
    return next()
  }

  return router.push({ name: 'AuthLogin', params: {org_code: to.params.org_code} })
}
