import axios from 'axios'
import store from '@/store'
import Client from '@/apis/liff/CoreGroupBuying'
import CouponClient from '@/apis/liff/Coupon'
import { processLiffState } from './helpers'

const PATH_PATTERN = '%s/liff/group-buying%s'

function tokenProvider () {
  return store.getters['liffCoreGroupBuying/token']
}

async function liffInitializer () {
  await store.dispatch('liffCoreGroupBuying/removeToken')
  await store.dispatch('liffCoreGroupBuying/init')
}

function couponTokenProvider () {
  return store.getters['liffCoupon/token']
}

async function couponLiffInitializer () {
  await store.dispatch('liffCoupon/removeToken')
  await store.dispatch('liffCoupon/init')
}

export default async function liffCoreGroupBuying({ next, to }) {
  const orgCode = to.params.orgCode
  const baseURL = `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/liff/core-groupbuyings`
  const couponBaseURL = `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/liff/redeem-codes`

  console.debug(`navigated to: ${to.fullPath}`)

  store.commit('liffCoreGroupBuying/SET_API_BASE_URL', baseURL)

  if (to.query['liff.state']) {
    return processLiffState({ to, next, orgCode, pathPattern: PATH_PATTERN })
  }

  // FIXMI: 先硬解
  if (!Client.instance) {
    await Client.init({ axios, baseURL, tokenProvider, liffInitializer })
    await CouponClient.init({ axios, baseURL: couponBaseURL, tokenProvider: couponTokenProvider, liffInitializer: couponLiffInitializer })
  }

  await store.dispatch('liffCoreGroupBuying/fetchUser')

  return next()
}
