import https from "./https";
import store from "@/store";

const notification = {
  getNotifications(orgId, params) {
    return https.get(`admin/organizations/${orgId}/notifications`, { params });
  },

  getOneNotification(notificationId) {
    const organization = store.state.general.organization;

    return https.get(
      `admin/organizations/${organization.id}/notifications/${notificationId}`
    );
  },

  updateNotification(orgId, notification) {
    const url = `admin/organizations/${orgId}/notifications/` + notification.id;

    return https.put(url, notification);
  },

  createNotification(orgId, notification) {
    const url = `admin/organizations/${orgId}/notifications`;

    return https.post(url, notification);
  },

  uploadExcelNotification(orgId, formData) {
    const url = `admin/organizations/${orgId}/notifications/action/upload-excel`;

    return https.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getBranchNotifications(branchId, params) {
    const organization = store.state.general.organization;
    const url = `/admin/organizations/${organization.id}/branches/${branchId}/notifications`;

    return https.get(url, { params });
  },

  updateBranchNotificationRead(branchId, notificationId) {
    const organization = store.state.general.organization;
    const url = `/admin/organizations/${organization.id}/branches/${branchId}/notifications/${notificationId}/action/read`;

    return https.put(url);
  },

  getMyNotifications(params) {
    const url = "/admin/me/notifications";

    return https.get(url, { params });
  },

  deleteNotification(notificationID) {
    const organization = store.state.general.organization;
    const url = `/admin/organizations/${organization.id}/${notificationID}`;

    return https.delete(url);
  },
};

export default notification;
