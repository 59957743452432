import Client from '@/apis/liff/Chatroom'
import { state, mutations, getters, actions } from './base'

export default {
  namespaced: true,
  state: {
    ...state(),
    shouldCheckFollowStatus: false,
    asStaff: false,
  },
  mutations: {
    ...mutations(),
    SET_AS_STAFF(state, bool) {
      state.asStaff = bool
    }
  },
  getters: getters(),
  actions: {
    ...actions(Client),
    setAsStaff({ commit }, bool) {
      commit('SET_AS_STAFF', bool)
    },
    async fetchUser ({ commit, state }) {
      commit('SET_USER', await Client.getInstance().getCustomer({ asStaff: state.asStaff }))
    },
    getChatrooms ({ state }) {
      return Client.getInstance().getChatrooms({asStaff: state.asStaff})
    },
    getChatroom (_, { chatroomId }) {
      return Client.getInstance().getChatroom({ chatroomId })
    },
    getMessages ({ state }, { chatroomId, cursor, count = 50 }) {
      return Client.getInstance().getMessages({ chatroomId, cursor, count, asStaff: state.asStaff })
    },
    sendMessage (_, { chatroomId, message, state }) {
      return Client.getInstance().sendMessage({ chatroomId, message, state, asStaff: _.state.asStaff })
    },
    markAsRead (_, { chatroomId }) {
      return Client.getInstance().markAsRead({ chatroomId, asStaff: _.state.asStaff })
    },
    goToChat (_, { sourceType, sourceId, chatroomId = null }) {
      return Client.getInstance().goToChat({ sourceType, sourceId, chatroomId })
    },
    goToChatStaff (_, { sourceType, sourceId, skuId }) {
      return Client.getInstance().goToChatStaff({ sourceType, sourceId, skuId })
    },
    upload (_, { chatroomId, files }) {
      return Client.getInstance().upload({ chatroomId, files })
    },
    markAsUploaded (_, { chatroomId, state, files }) {
      return Client.getInstance().markAsUploaded({ chatroomId, state, files })
    },
    getConfig() {
      return Client.getInstance().getConfig()
    },
    generateMultipleUploadToken (_, { payload }) {
      return Client.getInstance().generateMultipleUploadToken({ payload })
    },
  }
}
