import module from '../../../../apis/liff/v2/module';

export default {
  namespaced: true,
  state: {
    modules: [],
  },
  getters: {
    getModule: (state) => (code) => {
      return state.modules.find(module => module.module_code === code)
    },
    getConfig: (state, getters) => (code, configKey) => {
      let module = getters.getModule(code)
      if (!module) return undefined

      const keys = configKey.split("."); // 將 key 字串以 . 分隔成陣列
      return keys.reduce((obj, key) => obj ? obj[key] : undefined, module.module_config);
    },
  },
  mutations: {
    SET_MODULE(state, module) {
      if (!state.modules.find((m) => m.module_code === module.module_code)) {
        state.modules.push(module)
      }
    }
  },
  actions: {
    async fetchModule (context, moduleCode) {
      if (context.getters.getModule(moduleCode)) {
        console.debug(`module loaded`, moduleCode)
        return
      }

      let response = await module.getModule(moduleCode)
      context.commit('SET_MODULE', response.data.data)
      console.debug('fetch module', response.data.data)
    },
  },
};
