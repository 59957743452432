import axios from 'axios'
import store from '@/store'
import Client from '@/apis/liff/Huggies'
import { processLiffState } from './helpers'
import router from "../../index";

const PATH_PATTERN = '%s/liff/chatrooms%s'
const ROUTE_PORTAL = 'LiffChatroomPortal'
const ROUTE_INDEX = 'LiffChatroomIndex'

function tokenProvider () {
  return store.getters['liffHuggies/token']
}

async function liffInitializer () {
  await store.dispatch('liffHuggies/removeToken')
  await store.dispatch("liffHuggies/initLiff");
}

export default async function huggiesUseApiClient({ next, to }) {
  const orgCode = to.params.org_code
  const baseURL = `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/liff/huggies`

  console.debug(`navigated to: ${to.fullPath}`)

  store.commit('liffHuggies/SET_ORG_CODE', orgCode)
  store.commit('liffHuggies/SET_API_BASE_URL', baseURL)


  if (to.query['liff.state']) {
    return processLiffState({ to, next, orgCode, pathPattern: PATH_PATTERN })
  }

  if (to.name === ROUTE_PORTAL) {
    return next({ name: ROUTE_INDEX, params: { orgCode } })
  }

  if (!Client.instance) {
    await Client.init({ axios, baseURL, tokenProvider, liffInitializer })
  }

  await store.dispatch("liffHuggies/fetchMe");

  // 會員專區一進來，如果手機沒有驗證（就是代表他還沒加入會員）要先導去註冊條款頁。
  if (! store.getters['liffHuggies/isPhoneVerified']) {
    let nextUrl = router.resolve({
      name: 'LiffHuggiesMomoMemberIndex',
      params: { org_code: orgCode },
    }).href;

    router.push({
      name: "LiffHuggiesTerms",
      params: { org_code: orgCode },
      query: { redirect_url: nextUrl, redirect_text: "回會員專區" }
    }).then(() => {
      store.dispatch("liffHuggies/setLoading", false);
    })
    return;
  }

  return next()
}
