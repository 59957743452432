import axios from 'axios'
import store from '@/store'
import Client from '@/apis/liff/RedeemCode'
import { processLiffState } from './helpers'
import VConsole from 'vconsole';

const PATH_PATTERN = '%s/liff/redeem%s'

function tokenProvider () {
  return store.getters['liffRedeemCode/token']
}

async function liffInitializer () {
  await store.dispatch('liffRedeemCode/removeToken')
  await store.dispatch('liffRedeemCode/init')
}

export default async function liffRedeemCode({ next, to }) {
  const orgCode = to.params.orgCode
  const baseURL = `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/liff/redeem-codes`

  console.debug(`navigated to: ${to.fullPath}`)

  if (to.query['vconsole']) {
    new VConsole({ theme: 'dark' });
  }

  if (to.query['liff.state']) {
    return processLiffState({ to, next, orgCode, pathPattern: PATH_PATTERN })
  }

  if (!Client.instance) {
    await Client.init({ axios, baseURL, tokenProvider, liffInitializer })
  }

  return next()
}
