import liff from '@line/liff'
import store from '@/store'
import authApi from "@/apis/liff/v2/auth";
import VConsole from 'vconsole';

/**
 * middleware: auth
 *
 * 只有登入過的用戶才能訪問
 */
export default async function auth({ next, to }) {
  if (to.query['vconsole']) {
    new VConsole({ theme: 'dark' });
  }

  // 如果遇到 liff 的驗證失敗，要有相容機制
  const queryParams = new URLSearchParams(window.location.search);

  // Check if error query parameter exists
  const hasErrorParam = queryParams.has('error');

  if (hasErrorParam) {
    // Remove error and error_message parameters from the query parameters
    queryParams.delete('error');
    queryParams.delete('error_description');
    queryParams.delete('state');
    queryParams.delete('liffOAuth2Error');

    // Reconstruct the URL without error and error_message parameters
    const cleanUrl = `${window.location.pathname}?${queryParams.toString()}`;

    window.location.href = cleanUrl;

    await new Promise(() => {});
  }

  const liffId = process.env.VUE_APP_LIFF_ID || store.state.liffGeneral.moduleConfig.liff_id

  await liff.init({ liffId })

  if (process.env.VUE_APP_DEBUG !== "true" && !liff.isLoggedIn()) {
    liff.login({ redirectUri: window.location.href })
    await new Promise(() => {})
  }

  const token = liff.getAccessToken() || process.env.VUE_APP_MOCK_VUEX_ACCESS_TOKEN;

  console.debug('token from liff', token);

  store.commit("liffAuth/SET_TOKEN", token);

  if (!store.state.liffAuth.user.name) {
    try {
      await store.dispatch('liffAuth/fetchMe');
    } catch (error) {
      // 清除 localStorage
      const removeItems = ['context', 'IDToken', 'clientId', 'accessToken', 'decodedIDToken'];
      removeItems.forEach(async (item) => {
        await localStorage.removeItem(`LIFF_STORE:${liffId}:${item}`);
      });
      liff.login({ redirectUri: window.location.href })
      await new Promise(() => {})
    }
  }

  // Profile +
  const idToken = liff.getDecodedIDToken();
  console.debug('idToken', idToken);
  console.debug('liffAuth.user', store.state.liffAuth.user);

  if (!idToken) {
    return next();
  }

  let profilePlusUpdate = {};
  if (!store.state.liffAuth.user.mobile_phone && idToken['phone_number']) {
    // 這裡會收到 +8869xxxxxxxx 格式的電話號碼，要轉成 09xxxxxxxx
    let phone = idToken['phone_number'].replace('+886', '0');
    profilePlusUpdate.mobile_phone = phone;
  }
  if (!store.state.liffAuth.user.sexual && idToken['gender']) {
    profilePlusUpdate.sexual = idToken['gender']
  }
  if (!store.state.liffAuth.user.birthday && idToken['birthdate']) {
    profilePlusUpdate.birthday = idToken['birthdate'];
  }
  if (!store.state.liffAuth.user.email && idToken['email']) {
    profilePlusUpdate.email = idToken['email'];
  }

  if (Object.keys(profilePlusUpdate).length !== 0) {
    await authApi.updateMeViaProfilePlus(profilePlusUpdate);
    // 結束更新以後要記得 fetchMe 拿取最新的資料
    await store.dispatch('liffAuth/fetchMe');
  }

  return next()
}
