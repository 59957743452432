// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue } from "bootstrap-vue";
import VueSlideBar from "vue-slide-bar";
import VueSweetalert2 from "vue-sweetalert2";
import VueGoodWizard from "vue-good-wizard";
import ValidationErrorAlert from "./components/ValidationErrorAlert";
import Loading from "./components/Loading";
import permissions from "./utils/permissions";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import VueQRCodeComponent from "vue-qrcode-component";
import CKEditor from "@ckeditor/ckeditor5-vue2";

const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
};

Vue.use(BootstrapVue);
Vue.use(VueSweetalert2, options);
Vue.use(VueGoodWizard);
// Vue.use(BootstrapVueIcons);
Vue.use(permissions);
Vue.use(CKEditor);

Vue.component("vue-slide-bar", VueSlideBar);
Vue.component("validation-error-alert", ValidationErrorAlert);
Vue.component("larave-vue-pagination", require("laravel-vue-pagination"));
Vue.component("loading", Loading);
Vue.component("qr-code", VueQRCodeComponent);

Vue.config.productionTip = false;

Vue.filter("hiddenString", function (value, length) {
  value = value || ''
  const splittedValue = [...value]
  if (splittedValue.length <= length) {
    return value
  }
  return splittedValue.slice(0, length).join('') + '...'
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
