import auth from '@/apis/auth'

export default {
  namespaced: true,
  state: {
    user: {},
    token: null,
    isLogin: false,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
      state.isLogin = (token !== null)
    },
    SET_USER(state, user) {
      state.user = user
    },
  },
  actions: {
    async login(context, params) {
      let response = await auth.login(params)

      if (response.data.status === 'success') {
        context.dispatch('dashboardModule/resetModules', null, { root: true });
        context.dispatch('general/resetDashboardModule', null, { root: true });
        context.commit("SET_TOKEN", response.data.data.token)
        context.commit("SET_USER", response.data.data.user)
      }
    },
    async loginByToken(context, params) {
      let response = await auth.loginByToken(params)

      if (response.data.status === 'success') {
        context.dispatch('dashboardModule/resetModules', null, { root: true });
        context.dispatch('general/resetDashboardModule', null, { root: true });
        context.commit("SET_TOKEN", response.data.data.token)
        context.commit("SET_USER", response.data.data.user)
      }
    },
    async loginBySso(context, params) {
      let response = await auth.loginBySso(params)

      if (response.data.status === 'success') {
        context.dispatch('dashboardModule/resetModules', null, { root: true });
        context.dispatch('general/resetDashboardModule', null, { root: true });
        context.commit("SET_TOKEN", response.data.data.token)
        context.commit("SET_USER", response.data.data.user)
      }
    },
    async oAuthLogin(context, params) {
      let response = await auth.oAuthLogin(params)

      if (response.data.status === 'success') {
        context.dispatch('dashboardModule/resetModules', null, { root: true });
        context.dispatch('general/resetDashboardModule', null, { root: true });
        context.commit("SET_TOKEN", response.data.data.token)
        context.commit("SET_USER", response.data.data.user)
      }
    },
    logout (context) {
      context.dispatch('dashboardModule/resetModules', null, { root: true });
      context.dispatch('general/resetDashboardModule', null, { root: true });
      context.commit("SET_TOKEN", null)
      context.commit("SET_USER", null)
      context.dispatch('general/reset', null, { root: true })
    },
    async fetchMe (context) {
      let response = await auth.fetchMe();

      if (response.data.status === 'success') {
        context.commit("SET_USER", response.data.data);
      }
    }
  },
};
