import store from '@/store'
import router from '@/router'

/**
 * middleware: guest
 *
 * 只有訪客才能訪問，像是「登入頁」
 */
export default function guest({ next, to }) {
  if (store.state.auth.isLogin) {
    return router.push({
      name: 'DashboardHome',
      params: {
        org_code: to.params.org_code
      }
    })
  }

  if (
    (store.state.general.organization && ! store.state.general.organization.is_enabled) ||
    ! store.state.general.organization
  ) {
    return router.push({
      name: 'Error404',
    })
  }

  return next()
}
