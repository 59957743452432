import store from '@/store'
import liff from '@line/liff'
import router from '@/router'


/**
 * middleware: getOrganizationData
 *
 * 凡經過這層 Middleware，都必須先取得 Organization 的資料，以便畫面中使用，屬於非驗證類型 middleware
 */
export default async function getOrganizationData({ next, to }) {
  const org_code = to.params.org_code

  if (! store.state.liffCustomerBind.lineData || store.state.liffCustomerBind.organization) {
    await store.dispatch('liffCustomerBind/init', org_code)
  }

  if (store.state.liffCustomerBind.liffForceFriend === true && to.path !== `/${to.params.org_code}/liff/follow`) {
    let friendship = await liff.getFriendship()
    if (friendship.friendFlag === false) {
      return router.push('/' + to.params.org_code + '/liff/follow')
    }
  }

  return next()
}
