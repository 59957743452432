import router from '@/router'

export default {
  namespaced: true,
  state: {
    history: [],
    intended: null,
    routeParams: {},
    routeQuery: {},
    historyQuery:[],
    redirectAction: null,
    buttonDisplay: {
      redirect_button: null,
      success_button: null,
      go_to_button: null,
    }
  },
  getters: {
    from(state) {
      return state.history[state.history.length - 2]
    },
    hasIntended(state) {
      return !!state.intended
    },
    routeQuery(state) {
      return state.routeQuery
    },
    routeParams(state) {
      return state.routeParams
    },
    historyQuery: (state) => (name) => {
      return state.historyQuery[name] || null;
    },
    buttonDisplayList(state) {
      return state.buttonDisplay;
    },
    redirectAction(state) {
      return state.redirectAction ?? null;
    }
  },
  mutations: {
    PUSH(state, route) {
      if (state.history.length && state.history[state.history.length - 1] === route) {
        return
      }

      state.history.push(route)
    },
    SET_INTENDED(state, route) {
      state.intended = route
    },
    SET_ROUTE_QUERY(state, params) {
      state.routeQuery = params;
    },
    SET_ROUTE_PARAMS(state, params) {
      state.routeParams = params;
    },
    SET_HISTORY_QUERY(state, {name, query}) {
      state.historyQuery[name] = query;
    },
    SET_REDIRECT_ACTION(state, action) {
      state.redirectAction = action;
    },
    SET_BUTTON_DISPLAY(state, buttonDisplay) {
      state.buttonDisplay = buttonDisplay;
    }
  },
  actions: {
    intended({ state, commit }, fallback = '/') {
      if (!state.intended) {
        return router.push(fallback)
      }

      const intended = state.intended

      commit('SET_INTENDED', null)

      if (intended.startsWith('http')) {
        return window.location = intended
      }

      return router.push(intended)
    },
  },
}
