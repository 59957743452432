import axios from 'axios'
import store from '@/store'
import Client from '@/apis/liff/GroupBuying'
import IntendedRoute from '@/utils/liff/IntendedRoute'
import { getMeta, processLiffState } from './helpers'

const PATH_PATTERN = '%s/liff/group-buying%s'
const ROUTE_PORTAL = 'LiffGroupBuyingPortal'
const ROUTE_ORDERS = 'LiffGroupBuyingOrders'
const ROUTE_AUTH_FOLLOW = 'LiffGroupBuyingAuthFollow'

function tokenProvider () {
  return store.getters['liffGroupBuying/token']
}

async function liffInitializer () {
  await store.dispatch('liffGroupBuying/removeToken')
  await store.dispatch('liffGroupBuying/init')
}

export default async function liffGroupBuying({ next, from, to }) {
  const meta = getMeta(to)
  const orgCode = to.params.orgCode
  const baseURL = `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/liff/groupbuying`

  console.debug(`navigated to: ${to.fullPath}`)

  store.commit('liffGroupBuying/SET_API_BASE_URL', baseURL)

  if (to.query['liff.state']) {
    return processLiffState({ to, next, orgCode, pathPattern: PATH_PATTERN })
  }

  if (to.name === ROUTE_PORTAL) {
    return next({ name: ROUTE_ORDERS, params: { orgCode } })
  }

  if (!Client.instance) {
    await Client.init({ axios, baseURL, tokenProvider, liffInitializer })
  }

  await store.dispatch('liffGroupBuying/fetchUser')

  const user = store.getters['liffGroupBuying/user']

  if (from.name === to.name) {
    return next()
  }

  if (!store.getters['liffGroupBuying/oaIsFollowed'] && to.name !== ROUTE_AUTH_FOLLOW) {
    IntendedRoute.set(to)

    return next({ name: ROUTE_AUTH_FOLLOW, params: { orgCode } })
  }


  if (meta.boundOnly && !user.isBound()) {
    await store.dispatch('liffGroupBuying/sso', { baseURL })
  }

  if (meta.notBoundOnly && user.isBound()) {
    return next({ name: ROUTE_ORDERS, params: { orgCode } })
  }

  return next()
}
