import Client from '@/apis/liff/MemberCard'
import { state, mutations, getters, actions } from './base'

export default {
  namespaced: true,
  state: state(),
  mutations: mutations(),
  getters: getters(),
  actions: {
    ...actions(Client),
    sso ({ state, getters }) {
      const token = getters.token
      const redirect = window.location.href

      window.location = `${state.apiBaseURL}/sso?line_access_token=${token}&redirect_url=${redirect}`

      return new Promise(() => {})
    },
    getCard () {
      return Client.getInstance().getCard()
    },
    getTransactions (_, { page, months, count }) {
      return Client.getInstance().getTransactions({ page, months, count })
    },
    getTransaction (_, { branch, order, time, machine }) {
      return Client.getInstance().getTransaction({ branch, order, time, machine })
    },
    getPoints (_, { page, months, count }) {
      return Client.getInstance().getPoints({ page, months, count })
    },
  }
}
