import store from '@/store'
import Swal from 'sweetalert2'

/** Liff General Store Initialized */
export default async function liffGeneralStoreInit({ next, to }) {
  let isInit = store.state.liffGeneral.isInit;

  if (!isInit) {
    try {
      await store.dispatch('liffGeneral/init', to.params.orgCode ?? to.params.org_code ?? null);
    } catch (error) {
      console.error(error);
      await Swal.fire({
        type: "error",
        title: "尚未開通 LIFF 功能",
        text: "請與我們聯繫",
        showCloseButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      return;
    }
  }

  if (store.state.liffGeneral?.moduleConfig?.custom_domain) {
    const customDomain = store.state.liffGeneral?.moduleConfig?.custom_domain;

    const currentURL = window.location.href;
    const domain = new URL(currentURL).hostname;
    const path = new URL(currentURL).pathname;
    const protocol = new URL(currentURL).protocol;

    // 如果與客製化 domain 不相同，則轉導去客製化 domain
    if (customDomain !== domain) {
      const newUrl = protocol + "//" + customDomain + path;

      window.location.href = newUrl;

      return;
    }
  }

  return next();
}
