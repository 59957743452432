import axios from "axios";
import liff from "@line/liff";
import { setTitle, setFavicon, setMeta } from "./helpers";
import { state, mutations, getters, actions, mockCommit} from './base'
import Client from '@/apis/liff/Huggies'

// TODO 因改使用 base.js，有些重複項目可清掉。

const SECONDS = 180;

export default {
  namespaced: true,
  state: {
    ...state(),
    orgCode: null,
    isLoading: false,
    apiBaseURL: null,
    initialized: false,
    liffInfo: {},
    oaIsFollowed: true,
    token: process.env.VUE_APP_LINE_TOKEN,
    user: null,
    me: null,
    lineProfile: null,
    /** 簡訊驗證 */
    timer: null,
    seconds: SECONDS,

    /** For coupon */
    eventCode: null,
    redeemEvent: null,
    redeemCode: null,
    availablePoints: 0,
  },
  mutations: {
    ...mutations(),
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_ORG_CODE(state, orgCode) {
      state.orgCode = orgCode;
    },
    SET_LIFF_INFO(state, info) {
      state.liffInfo = info;
    },
    SET_OA_IS_FOLLOWED(state, value) {
      state.oaIsFollowed = value;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_ME(state, me) {
      state.me = me;
    },
    SET_MOBILE(state, mobile) {
      state.me['mobile'] = mobile
    },
    SET_LINE_PROFILE(state, profile) {
      state.lineProfile = profile;
    },
    SET_TIMER(state, timer) {
      state.timer = timer;
    },
    CLEAN_TIMER(state) {
      clearInterval(state.timer);
      state.timer = null;
    },
    SET_SECONDS(state, seconds) {
      state.seconds = seconds;
    },

    /** For Coupon */
    SET_EVENT_CODE(state, eventCode) {
      state.eventCode = eventCode;
    },
    SET_REDEEM_EVENT(state, redeemEvent) {
      state.redeemEvent = redeemEvent;
    },
    SET_REDEEM_CODE(state, redeemCode) {
      state.redeemCode = redeemCode;
    },

    // 可用點數
    SET_AVAILABLE_POINTS(state, num) {
      state.availablePoints = num;
    },
  },
  getters: {
    ...getters(),
    isLoading: (state) => state.isLoading,
    liffInfo({ liffInfo }) {
      return liffInfo;
    },
    liffId({ liffInfo }) {
      return process.env.VUE_APP_LIFF_ID || liffInfo.liff_id;
    },
    oaId({ liffInfo }) {
      return process.env.VUE_APP_LIFF_OA_ID || liffInfo.line_oa_id;
    },
    oaIsFollowed({ oaIsFollowed }) {
      return oaIsFollowed;
    },
    token({ token }) {
      return token;
    },
    isPhoneVerified({ me }) {
      return me?.phone_verified;
    },
    lineProfile({ lineProfile }) {
      return lineProfile;
    },
    me({ me }) {
      return me;
    },
    timer({ timer }) {
      return timer;
    },
    seconds({ seconds }) {
      return seconds;
    },
    availablePoints({availablePoints}) {
      return availablePoints;
    }
  },
  actions: {
    ...actions(Client),
    setOrgCode({ commit }, { orgCode }) {
      commit("SET_ORG_CODE", orgCode);
    },
    setLoading({ commit }, isLoading) {
      commit("SET_IS_LOADING", isLoading);
    },
    setTimer({ commit, state }, inputSeconds = null) {
      if (!inputSeconds) {
        commit("SET_SECONDS", SECONDS);
      } else {
        commit("SET_SECONDS", inputSeconds);
      }

      const timer = setInterval(() => {
        commit("SET_SECONDS", state.seconds - 1);
        if (state.seconds === 0) {
          commit("CLEAN_TIMER");
        }
      }, 1000);
      commit("SET_TIMER", timer);
    },
    clearTimer({ commit }) {
      commit("CLEAN_TIMER");
      commit("SET_SECONDS", 0);
    },
    async fetchLiffInfo({ commit, state }) {
      if (mockCommit(commit, "SET_LIFF_INFO", () => process.env.VUE_APP_MOCK_VUEX_LIFF_INFO)) {
        return
      }
      const url =
        process.env.VUE_APP_API_BASE_URL +
        `/${state.orgCode}/liff/general/info`;
      await axios
        .get(url)
        .then((response) => {
          commit("SET_LIFF_INFO", response.data.data);
        })
        .catch(() => {
          alert("初始化頁面失敗，請確認此網址是否正確");
        });
    },
    async initLiff({ commit, dispatch, getters, state }) {
      console.debug("init liff");

      await dispatch("fetchLiffInfo");

      setTitle(state.liffInfo.liff_og_title);
      setFavicon(state.liffInfo.liff_icon_url);
      setMeta("og:site_title", state.liffInfo.liff_og_title);
      setMeta("og:description", state.liffInfo.liff_og_description);
      setMeta("og:image", state.liffInfo.liff_og_image);

      const liffId = getters.liffId;

      console.debug(`liff id: ${liffId}`);
      await liff.init({ liffId });

      if (process.env.VUE_APP_DEBUG !== "true" && !liff.isLoggedIn()) {
        liff.login({ redirectUri: window.location.href });
        await new Promise(() => {});
      }

      if (process.env.VUE_APP_DEBUG !== "true" && getters.oaId) {
        const friendship = await liff.getFriendship();
        const isFollowed = friendship.friendFlag;

        console.debug(`liff oa is followed: ${isFollowed}`);

        commit("SET_OA_IS_FOLLOWED", isFollowed);
      }

      if (!mockCommit(commit, "SET_LINE_PROFILE", () => process.env.VUE_APP_MOCK_VUEX_LINE_PROFILE)) {
        const lineProfile = await liff.getProfile();
        commit("SET_LINE_PROFILE", lineProfile);
      }

      if (!mockCommit(commit, "SET_TOKEN", () => process.env.VUE_APP_MOCK_VUEX_ACCESS_TOKEN)) {
        const token = liff.getAccessToken();
        console.debug(`liff token: ${token}`);
        commit("SET_TOKEN", token);
      }

      commit("SET_INITIALIZED");
    },
    async legacyFetchMe({ commit, state }) {
      const url =
        process.env.VUE_APP_API_BASE_URL + `/${state.orgCode}/liff/general/me`;
      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((response) => {
          commit("SET_ME", response.data.data);
        })
        .catch(() => {});
    },

    async fetchMe ({ dispatch, commit }) {
      if (mockCommit(commit, "SET_ME", () => process.env.VUE_APP_MOCK_VUEX_ME)) {
        return
      }

      if (!Client.instance) {
        return await dispatch("legacyFetchMe");
      }
      commit('SET_ME', await Client.getInstance().getCustomer())
    },

    /** 填寫問卷表單 */
    async saveEvent({ state }, { event_code, form }) {
      const url =
        process.env.VUE_APP_API_BASE_URL +
        `/${state.orgCode}/liff/general/events/${event_code}`;
      return await axios.post(url, form, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
    },

    /** 取得問卷基本資訊（問卷的 mode 有：once, update, multiple 三種） */
    async getEventMode({ state }, { event_code }) {
      const url =
        process.env.VUE_APP_API_BASE_URL +
        `/${state.orgCode}/liff/general/events/${event_code}`;
      return await axios.get(url, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
    },

    async getEventFieldCount({ state }, { event_code, field, values }) {
      const url =
        process.env.VUE_APP_API_BASE_URL +
        `/${state.orgCode}/liff/general/events/${event_code}/field-count?field=${field}&${values.map((n, index) => `values[${index}]=${n}`).join('&')}`;
      return await axios.get(url, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
    },

    setEventCode({commit}, eventCode) {
      commit('SET_EVENT_CODE', eventCode);
    },

    /** Get Coupon */
    async fetchRedeemEvent({ commit, state }) {
      const url =
        process.env.VUE_APP_API_BASE_URL +
        `/${state.orgCode}/liff/redeem/redeem-event?event_code=${state.eventCode}`;

      try {
        let response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          }
        });

        commit('SET_REDEEM_EVENT', response.data.data.event);
        commit('SET_REDEEM_CODE', response.data.data.redeem_code);
      } catch (error) {
        console.error(error);
        alert('讀取資料錯誤');
        return;
      }
    },
    async fetchAvailablePoints({ commit }) {
      commit(
        "SET_AVAILABLE_POINTS",
        (await Client.getInstance().getAvailablePoints()) || 0
      );
    },

    async updateCustomer({commit, getters}, { email, mobile, name, county, district, zipcode, address }) {
      try {
        let data = await Client.getInstance().updateCustomer({ email, mobile, name, county, district, zipcode, address })
        let customer = {
          ...getters.me,
          ...{ email, mobile, name, county, district, zipcode, address }
        }
        commit('SET_ME', customer)
        return data
      } catch (error) {
        console.error(error)
        alert('更新會員資料失敗')
      }
    },

    async redeemGift({dispatch}, gift) {
      try {
        let data = await Client.getInstance().redeemGift(gift)
        await dispatch('fetchAvailablePoints')
        return data
      } catch (error) {
        console.error(error)
        throw error
      }
    }
  },
};
