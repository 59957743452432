import liff from '@line/liff'
import { setTitle, setFavicon, setMeta } from './helpers'

const MERCHANT_EC_91 = 'EC_91'

export function mockCommit(commit, name, dataRetriever) {
  let data = undefined
  try {
    data = dataRetriever()
    // process.env.foo 會強制轉型為 string 因此這裡需判斷 "undefined"
    if (data === undefined || data === "undefined") {
      return false
    }
    try {
      data = JSON.parse(data)
    } catch (error) {
      // ignore
    }
  } catch (error) {
    console.error("mockCommit dataRetriever failed, name = " + name)
    console.error(error)
  }

  if (data) {
    commit(name, data)
    return true // mocked
  }
  return false // not mocked
}

export function state() {
  return {
    shouldCheckFollowStatus: true,
    orgCode: null,
    apiBaseURL: null,
    initialized: false,
    liffInfo: {},
    oaIsFollowed: true,
    token: null,
    user: null,
  }
}

export function mutations() {
  return {
    SET_ORG_CODE (state, orgCode) {
      state.orgCode = orgCode
    },
    SET_API_BASE_URL (state, url) {
      state.apiBaseURL = url
    },
    SET_INITIALIZED (state) {
      state.initialized = true
    },
    SET_LIFF_INFO (state, info) {
      state.liffInfo = info
    },
    SET_OA_IS_FOLLOWED (state, value) {
      state.oaIsFollowed = value
    },
    SET_TOKEN (state, token) {
      state.token = token
    },
    SET_USER (state, user) {
      state.user = user
    },
  }
}

export function getters() {
  return {
    orgCode ({ orgCode }) {
      return orgCode
    },
    liffInfo ({ liffInfo }) {
      return liffInfo
    },
    liffId ({ liffInfo }) {
      return process.env.VUE_APP_LIFF_ID || liffInfo.liff_id
    },
    oaId ({ liffInfo }) {
      return process.env.VUE_APP_LIFF_OA_ID || liffInfo.line_oa_id
    },
    oaIsFollowed({ oaIsFollowed }) {
      return oaIsFollowed
    },
    token ({ token }) {
      return token
    },
    user ({ user }) {
      return {
        ...user.user,
        isBound () {
          return user.bind_types.includes(MERCHANT_EC_91)
        },
        isBlacklist () {
          return user.is_blacklist
        },
        customer () {
          return user
        },
        member () {
          if (!user.user) {
            return {}
          }

          const customers = user.user.customers
          const customer = customers.find((customer) => customer.merchant.type === MERCHANT_EC_91)

          return customer || {}
        },
      }
    },
  }
}

export function actions(Client) {
  return {
    async fetchLiffInfo ({ commit }) {
      commit('SET_LIFF_INFO', await Client.getInstance().getLiffInfo())
    },
    async init ({ commit, dispatch, getters, state }) {
      console.debug('init liff')

      // 如果遇到 liff 的驗證失敗，要有相容機制
      const queryParams = new URLSearchParams(window.location.search);

      // Check if error query parameter exists
      const hasErrorParam = queryParams.has('error');

      if (hasErrorParam) {
        // Remove error and error_message parameters from the query parameters
        queryParams.delete('error');
        queryParams.delete('error_description');
        queryParams.delete('state');
        queryParams.delete('liffOAuth2Error');

        // Reconstruct the URL without error and error_message parameters
        const cleanUrl = `${window.location.pathname}?${queryParams.toString()}`;

        window.location.href = cleanUrl;

        await new Promise(() => {});
      }

      await dispatch('fetchLiffInfo')

      setTitle(state.liffInfo.liff_og_title)
      setFavicon(state.liffInfo.liff_icon_url)
      setMeta('og:site_title', state.liffInfo.liff_og_title)
      setMeta('og:description', state.liffInfo.liff_og_description)
      setMeta('og:image', state.liffInfo.liff_og_image)

      const liffId = getters.liffId

      console.debug(`liff id: ${liffId}`)

      await liff.init({ liffId })

      if (process.env.VUE_APP_DEBUG !== "true" && !liff.isLoggedIn()) {
        liff.login({ redirectUri: window.location.href })
        await new Promise(() => {})
      }

      if (process.env.VUE_APP_DEBUG !== "true" && state.shouldCheckFollowStatus && getters.oaId) {
        const friendship = await liff.getFriendship()
        const isFollowed = friendship.friendFlag

        console.debug(`liff oa is followed: ${isFollowed}`)

        commit('SET_OA_IS_FOLLOWED', isFollowed)
      }

      if (!mockCommit(commit, "SET_TOKEN", () => process.env.VUE_APP_MOCK_VUEX_ACCESS_TOKEN)) {
        const token = liff.getAccessToken();
        console.debug(`liff token: ${token}`);
        commit("SET_TOKEN", token);
      }
      commit('SET_INITIALIZED')
    },
    removeToken ({ commit }) {
      commit('SET_TOKEN', null)
    },
    async fetchUser ({ commit }) {
      commit('SET_USER', await Client.getInstance().getCustomer())
    },
    async sendVerifyCode (_, { mobileNumber }) {
      await Client.getInstance().sendVerifyCode({ mobileNumber })
    },
    checkMemberState (_, { mobileNumber, verifyCode }) {
      return Client.getInstance().checkMemberState({ mobileNumber, verifyCode })
    },
    login (_, { mobileNumber, password }) {
      return Client.getInstance().login({ mobileNumber, password })
    },
    updatePassword (_, { mobileNumber, password }) {
      return Client.getInstance().updatePassword({ mobileNumber, password })
    },
  }
}
