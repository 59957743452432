import Client from '@/apis/liff/GroupBuying'
import { state, mutations, getters, actions } from './base'

export default {
  namespaced: true,
  state: state(),
  mutations: mutations(),
  getters: getters(),
  actions: {
    ...actions(Client),
    sso ({ state, getters }) {
      const token = getters.token
      const redirect = window.location.href

      window.location = `${state.apiBaseURL}/sso?line_access_token=${token}&redirect_url=${redirect}`

      return new Promise(() => {})
    },
    getGroupBuying (_, { id }) {
      return Client.getInstance().getGroupBuying({ id })
    },
    createOrder(_, { id, specId, quantity }) {
      return Client.getInstance().createOrder({ id, specId, quantity })
    },
    getOrders (_, { status, page }) {
      return Client.getInstance().getOrders({ status, page })
    },
    getOrder (_, { id }) {
      return Client.getInstance().getOrder({ id })
    },
    updateOrder(_, { id, quantity }) {
      return Client.getInstance().updateOrder({ id, quantity })
    },
    cancelOrder (_, { id }) {
      return Client.getInstance().cancelOrder({ id })
    },
    setBlacklistRead(_, { id }) {
      return Client.getInstance().setBlacklistRead({ id })
    },
    getBranchCode(_, { id }) {
      return Client.getInstance().getBranchCode({ id })
    },
    getOrdersV2(_, { status, page }) {
      return Client.getInstance().getOrdersV2({ status, page })
    },
    getOrderV2(_, { id }) {
      return Client.getInstance().getOrderV2({ id })
    },
    updateOrderV2(_, { id, quantity }) {
      return Client.getInstance().updateOrderV2({ id, quantity })
    },
    cancelOrderV2(_, { id }) {
      return Client.getInstance().cancelOrderV2({ id })
    },
  },
}
