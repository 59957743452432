import { sprintf } from 'sprintf-js'

export function getMeta (to) {
  return to.matched.slice().reverse()[0].meta || {}
}

export function processLiffState ({ to, next, pathPattern, orgCode }) {
  console.debug(`liff state: ${to.query['liff.state']}`)

  const { 'liff.state': path, ...query } = to.query

  return next({
    query,
    path: sprintf(pathPattern, orgCode, decodeURIComponent(path)),
  })
}
