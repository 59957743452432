import https from "./https";
import store from "@/store";

const branch = {
  getBranches(organization, params) {
    const searchParams = new URLSearchParams(params);
    return https.get(
      `admin/organizations/${organization.id}/branches?${searchParams}`
    );
  },
  getMyBranches() {
    return https.get("admin/me/branches");
  },
  setCurrentBranch(branchId) {
    return https.put("admin/me/set-current-branch", {
      branch_id: branchId,
    });
  },
  createBranch(organization, params) {
    return https.post("admin/organizations/" + organization.id + "/branches", {
      ...params,
    });
  },
  updateBranch(branchID, params) {
    return https.put(`/admin/branches/${branchID}`, {
      ...params,
    });
  },
  updateBranchImages(branchId, images) {
    return https.patch(`/admin/branches/${branchId}/update-images`, {
      images
    });
  },
  getBranch(branchID) {
    return https.get(`admin/branches/${branchID}`);
  },
  deleteBranch(branchID) {
    return https.delete(`/admin/branches/${branchID}`);
  },
  getCurrentBelowBranches(params) {
    return https.get("admin/me/current-below-branches", {params});
  },
  import(formData) {
    const organization = store.state.general.organization;

    return https.post(
      `/admin/organizations/${organization.id}/branches/import`,
      formData
    );
  },
  importStaff(branchID, formData) {
    return https.post(
      `/admin/branches/${branchID}/import-staff`,
      formData
    );
  },
  getBranchCustomers(branchID, params) {
    return https.get(`admin/branches/${branchID}/customers`, {params});
  },
  getBranchStaffs(branchID, params) {
    return https.get(`admin/branches/${branchID}/all-staffs`, {params});
  },
};

export default branch;
