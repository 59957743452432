export default class Checker {
  constructor(context, customer, permissions) {
    this.context = context
    this.customer = customer
    this.permissions = permissions
  }

  check() {
    throw new Error('check() is not implemented')
  }

  onFailure() {
    throw new Error('onFailure() is not implemented')
  }

  redirect(route) {
    const { to, next } = this.context
    const orgCode = to.params.orgCode || to.params.org_code

    next({
      replace: true,
      ...route,
      params: {
        orgCode,
        ...(route.params || {}),
      },
    })
  }
}
