import https from "../../apis/https";
import { setTitle, setFavicon, setMeta } from './liff/helpers';

export default {
  namespaced: true,
  state: {
    isInit: false,
    orgCode: null,
    moduleConfig: {
      liff_id: null,
      liff_oa_id: null,
      meta: {},
      theme_config: {},
    },
  },
  getters:{
    meta(state) {
      return state.moduleConfig.meta ?? false;
    },
    themeConfig(state) {
      return state.moduleConfig.theme_config ?? false;
    },
    liffId: (state) => state.moduleConfig.liff_id ?? false,
    liffOaId: (state) => state.moduleConfig.liff_oa_id ?? false,
    orgCode: (state) => state.orgCode,
  },
  mutations: {
    SET_IS_INIT(state, isInit) {
      state.isInit = isInit;
    },
    SET_ORG_CODE(state, orgCode) {
      state.orgCode = orgCode;
    },
    SET_LIFF_MODULE_CONFIG(state, moduleConfig) {
      state.moduleConfig = moduleConfig;
    },
  },
  actions: {
    async init (context, orgCode) {
      context.commit('SET_ORG_CODE', orgCode);
      await context.dispatch('fetchModuleConfig');
      context.commit('SET_IS_INIT', true);
    },
    async fetchModuleConfig(context) {
      let response = await https.get(`/${context.state.orgCode}/liff/liff-init-module`);

      if (!response.data.data) {
        throw Error('no module config found or module config empty.');
      }

      context.commit('SET_LIFF_MODULE_CONFIG', response.data.data);

      setTitle(context.getters.meta.title);
      setFavicon(context.getters.meta.favicon);
      setMeta('og:site_title', context.getters.meta.title);
      setMeta('og:description', context.getters.meta.description);
      setMeta('og:image', context.getters.meta.image);
    },
  },
};
