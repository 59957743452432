<template>
  <div>
    <div class="loading"></div>
    <div class="loading-overlay"></div>
  </div>
</template>

<style>
.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(0,0,0,.2 );
  border-radius: 50%;
  border-top-color: rgba(0,0,0,.4 );
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50%);
  top: calc(50%);
  position: fixed;
  z-index: 9999;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 9998;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
</style>

<script>
export default {

}
</script>
