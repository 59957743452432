import store from '@/store';
import * as consts from "@/consts";

const has = (permission) => {
  const myPermissions = store.getters['general/permissions'];
  return myPermissions.map(permission => permission.key).includes(permission);
}

export default {
  install(Vue) {
    Vue.prototype.$permissions = {
      has: has,
      hasAll: (permissions) => {
        if (permissions === []) {
          return true;
        }
        return permissions.every(has);
      },
      hasAny: (permissions) => {
        if (permissions === []) {
          return false;
        }
        let result = false;
        permissions.forEach(permission => {
          if (has(permission)) {
            result = true;
          }
        });
        return result;
      },
      consts: consts,
    };
  }
}
