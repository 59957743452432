function createHeadTag(name, attributes) {
  const tag = document.createElement(name)

  for (const key in attributes) {
    tag.setAttribute(key, attributes[key])
  }

  document.getElementsByTagName('head')[0].appendChild(tag)

  return tag
}

function findOrCreateHeadTag(name, attributes = {}) {
  let query = name

  for (const key in attributes) {
    query += `[${key}="${attributes[key]}"]`
  }

  let tag = document.querySelector(query)

  if (!tag) {
    tag = createHeadTag(name, attributes)
  }

  return tag
}

export function setTitle(title) {
  document.title = title
}

export function setFavicon(path) {
  const tag = findOrCreateHeadTag('link', { rel: 'icon' })

  tag.setAttribute('href', path)
}

export function setMeta(property, content) {
  const tag = findOrCreateHeadTag('meta', { property })

  tag.setAttribute('content', content)
}
