import Client from '@/apis/liff/RedeemCode'
import { state, mutations, getters, actions } from './base'

export default {
  namespaced: true,
  state: state(),
  mutations: mutations(),
  getters: getters(),
  actions: {
    ...actions(Client),
    useRedeemCode (_, { code, event }) {
      return Client.getInstance().useRedeemCode({ code, event })
    },
    getRedeemEvent (_, { code }) {
      return Client.getInstance().getRedeemEvent({ code })
    },
  },
}
