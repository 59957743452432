import axios from 'axios'
import store from '@/store'
import Client from '@/apis/liff/MemberCard'
import { processLiffState } from './helpers'

const PATH_PATTERN = '%s/liff/membercard%s'
const ROUTE_PORTAL = 'LiffMemberCardPortal'
const ROUTE_AUTH_ME = 'LiffMemberCardAuthMe'

function tokenProvider () {
  return store.getters['liffMemberCard/token']
}

async function liffInitializer () {
  await store.dispatch('liffMemberCard/removeToken')
  await store.dispatch('liffMemberCard/init')
}

export default async function liffMemberCard({ next, from, to }) {
  const orgCode = to.params.orgCode
  const baseURL = `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/liff/membercard`

  console.debug(`navigated to: ${to.fullPath}`)

  store.commit('liffMemberCard/SET_API_BASE_URL', baseURL)

  if (to.query['liff.state']) {
    return processLiffState({ to, next, orgCode, pathPattern: PATH_PATTERN })
  }

  if (to.name === ROUTE_PORTAL) {
    return next({ name: ROUTE_AUTH_ME, params: { orgCode } })
  }

  if (!Client.instance) {
    await Client.init({ axios, baseURL, tokenProvider, liffInitializer })
  }

  await store.dispatch('liffMemberCard/fetchUser')

  if (from.name === to.name) {
    return next()
  }

  const user = store.getters['liffMemberCard/user']

  if (!user.isBound() && !to.query.error) {
    await store.dispatch('liffMemberCard/sso', { baseURL })
  }

  return next()
}
