import store from "@/store";
import moment from "moment";
import router from "../../index";

const EVENT_CODE = "huggie_2022_sample";
const EVENT_CODE_PREVIOUS = "huggie_2021_sample";

export default async function huggiesSample({ next, to }) {
  const org_code = to.params.org_code

  try {
    let { data: { data: currentEvent } } = await store.dispatch('liffHuggies/getEventMode', { event_code: EVENT_CODE });

    if (
      currentEvent.is_enabled === false ||
      moment(currentEvent.start_at).isAfter(moment()) ||
      ( (currentEvent.end_at) ? moment(currentEvent.end_at).isBefore(moment()) : false )
    ) {
      throw "親愛的好奇爸媽您好！謝謝各位的參與，派樣已全數發送完畢，請持續關注好奇官方LINE 活動訊息！";
    }

    if (currentEvent.last_record) {
      throw "親愛的好奇爸媽您好!您已領取過好奇裸感好動褲派樣包，謝謝您的持續參與!請繼續支持好奇紙尿褲!";
    }

    let { data: { data: previousEvent } } = await store.dispatch('liffHuggies/getEventMode', { event_code: EVENT_CODE_PREVIOUS });

    if (previousEvent.last_record) {
      throw "親愛的好奇爸媽您好!您去年已領取過好奇裸感好動褲派樣包，謝謝您的持續參與!請繼續支持好奇紙尿褲!";
    }
  } catch (error) {
    alert(error);
    store.dispatch("liffHuggies/setLoading", false);
    return next({ name: "LiffHuggiesSamplePage", params: { org_code } });
  }

  // Check Register or not
  if (! store.getters['liffHuggies/isPhoneVerified']) {
    let nextUrl = router.resolve({
      name: 'LiffHuggiesSample',
      params: { org_code },
    }).href;

    router.push({
      name: "LiffHuggiesTerms",
      params: { org_code },
      query: { redirect_url: nextUrl, redirect_text: "繼續索樣" }
    }).then(() => {
      store.dispatch("liffHuggies/setLoading", false);
    })
    return;
  }

  return next();
}
