import store from "@/store";
import router from "../../index";
import moment from "moment";

export default async function huggiesCoupon({ next, to }) {
  const org_code = to.params.org_code

  try {
    await store.dispatch('liffHuggies/setEventCode', 'huggie_2022_coupon');
    await store.dispatch('liffHuggies/fetchRedeemEvent');
  } catch (error) {
    console.log("error", error);
  }

  // 判斷活動時間
  try {
    let { data: { data: currentEvent } } = await store.dispatch('liffHuggies/getEventMode', { event_code: 'huggie_2022_coupon' });

    if (
      currentEvent.is_enabled === false ||
      moment(currentEvent.start_at).isAfter(moment()) ||
      ( (currentEvent.end_at) ? moment(currentEvent.end_at).isBefore(moment()) : false )
    ) {
      throw "親愛的好奇爸媽您好!目前非活動期間，請持續關注好奇官方LINE活動訊息!";
    }
  } catch (error) {
    alert(error);
    store.dispatch("liffHuggies/setLoading", false);
    return false;
  }

  // Check Register or not
  if (! store.getters['liffHuggies/isPhoneVerified']) {
    pushAndDisableLoading(
      "LiffHuggiesTerms",
      org_code,
      { redirect_url: window.location.href, redirect_text: "繼續兌換" }
    );
    return;
  }

  if (['LiffHuggiesCouponECFinished', 'LiffHuggiesCouponOfflineFinished'].includes(to.name)) {
    return next();
  }

  if (['LiffHuggiesCouponECRedeem', 'LiffHuggiesCouponOfflineRedeem'].includes(to.name)) {
    if (! store.state.liffHuggies.redeemCode) {
      return pushAndDisableLoading("LiffHuggiesCouponPage", org_code);
    }

    // 判斷是否還在 30 分鐘兌換期限內
    let now = Math.round(Date.now() / 1000);
    let redeemed = Math.round(Date.parse(store.state.liffHuggies.redeemCode.created_at) / 1000);
    console.log([redeemed, now]);

    // 如果還在期限內
    if ((redeemed + 1800) > now) {
      await store.dispatch("liffHuggies/clearTimer");
      await store.dispatch("liffHuggies/setTimer", redeemed + 1800 - now);
      return next();
    }

    // 如果超過期限，依照是否有 branch_id 來區分線上還是實體，將用戶導進對應畫面
    if (store.state.liffHuggies.redeemCode.branch_id !== null) {
      return pushAndDisableLoading("LiffHuggiesCouponOfflineFinished", org_code);
    } else {
      return pushAndDisableLoading("LiffHuggiesCouponECFinished", org_code);
    }
  } else {
    // 如果已經兌換過
    if (store.state.liffHuggies.redeemCode) {
      if (store.state.liffHuggies.redeemCode.branch_id !== null) {
        return pushAndDisableLoading("LiffHuggiesCouponOfflineFinished", org_code);
      } else {
        return pushAndDisableLoading("LiffHuggiesCouponECFinished", org_code);
      }
    }
  }

  return next();
}

const pushAndDisableLoading = (routeName, org_code, query) => {
  router.push({
    name: routeName,
    params: { org_code },
    query: query,
  }).then(() => {
    store.dispatch("liffHuggies/setLoading", false);
  })
}
