import store from "@/store";

export default class PermissionChecker {
  check = (permissions) => {
    if (permissions === []) {
      return true;
    }

    const myPermissions = store.getters["general/permissions"];

    const hasPermission = (element) => {
      return myPermissions
        .map((permission) => permission.key)
        .includes(element);
    };

    return permissions.every(hasPermission);
  };

  checkAny = (permissions) => {
    if (permissions === []) {
      return true;
    }

    const myPermissions = store.getters["general/permissions"];

    return permissions.some((permission) => {
      return myPermissions
        .map((myPermission) => myPermission.key)
        .includes(permission);
    });
  };
}
