import Client from './Client'

export default class CoreGroupBuying extends Client {
  async getGroupBuyings() {
    const { data } = await this.get()
    return data
  }

  async getGroupBuying({ id }) {
    console.log('id', id)
    const { data } = await this.get(`${id}`)
    return data
  }

  async createOrder({ id, skuId, quantity }) {
    const { data } = await this.post(`${id}/groupbuying-orders`, { sku_id: skuId, quantity })
    return data
  }

  async getBranches({ city, district }) {
    const { data } = await this.get('branches', { city, district })
    return data
  }

  async getBranchCities() {
    const { data } = await this.get('branch-cities')
    return data
  }

  async getBranchDistricts({ city }) {
    const { data } = await this.get('branch-districts', { city })
    return data
  }

  async getGroupBuyingOrders({ status }) {
    const { data } = await this.get(`groupbuying-orders?status=${status}`)
    return data
  }

  async getShareMessage(groupBuying) {
    const { data } = await this.get(`${groupBuying.id}/share-message`)
    return data
  }
}
