import https from "./https";

const auth = {
  login(params) {
    return https.post("/dashboard/auth/login", params);
  },
  loginByToken(params) {
    return https.post("/dashboard/auth/login-by-token", params);
  },
  loginBySso(params) {
    return https.post("/dashboard/auth/login-by-shopline-sso", params);
  },
  oAuthLogin(params) {
    return https.post("/oauth/login", params);
  },
  fetchMe() {
    return https.get("/admin/me");
  }
};

export default auth;
