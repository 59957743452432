import Client from './Client'

export default class GroupBuying extends Client {
  async getGroupBuying({ id }) {
    const { data } = await this.get(id)

    return data
  }

  async createOrder({ id, specId, quantity }) {
    const { data } = await this.post(`${id}/orders`, { sku_id: specId, quantity })

    return data
  }

  async getOrders({ status, page }) {
    const response = await this.get('orders', { status, page })

    return {
      data: response.data,
      currentPage: response.meta.current_page,
      lastPage: response.meta.last_page,
    }
  }

  async getOrder({ id }) {
    const { data } = await this.get(`orders/${id}`)

    return data
  }

  async updateOrder({ id, quantity }) {
    this.put(`orders/${id}`, { quantity })
  }

  async cancelOrder({ id }) {
    await this.delete(`orders/${id}`)
  }

  async setBlacklistRead({ id }) {
    await this.post(`${id}/set-blacklist-read`)
  }

  async getBranchCode({ id }) {
     const response = await this.get(`branches/${id}`)

     return response.data.branch_code
  }

  async logThemeGroupbuyingVisit (params) {
    const response = await this.get(`theme-groupbuyings/log-visit`, params)
    return response.data
  }

  async getOrdersV2({ status, page }) {
    const response = await this.get('orders-v2', { status, page })

    return {
      data: response.data,
      currentPage: response.meta.current_page,
      lastPage: response.meta.last_page,
    }
  }

  async getOrderV2({ id }) {
    const { data } = await this.get(`orders-v2/${id}`)

    return data
  }

  async cancelOrderV2({ id }) {
    await this.delete(`orders-v2/${id}`)
  }

  async updateOrderV2({ id, quantity }) {
    await this.put(`orders-v2/${id}`, { quantity })
  }
}
