import Client from './Client'

export default class GroupBuying extends Client {
  async getCard() {
    const { data } = await this.get('card')

    return data
  }

  async getTransactions({ page, months, count }) {
    const response = await this.get('transactions', { page, months, perpage: count })

    return {
      data: response.data,
      currentPage: response.current_page,
      lastPage: response.last_page,
    }
  }

  async getTransaction({ branch, order, time, machine }) {
    const { data } = await this.get('show-transaction', {
      branch_code: branch,
      outer_order_number: order,
      order_at: time,
      machine_name: machine,
    })

    return data
  }

  async getPoints({ page, months, count }) {
    const { data: { points } } = await this.get('points', { page, months, perpage: count })

    return {
      data: points.data,
      currentPage: points.current_page,
      lastPage: points.last_page,
    }
  }
}
