import axios from 'axios'
import liff from "@line/liff"

export default {
  namespaced: true,
  state: {
    orgCode: null,
    liffId: null,
    lineData: null,
    organization: null,
    themeConfig: null,
    lineOaId: null,
    liffForceFriend: false,
  },
  mutations: {
    SET_LIFF_ID(state, liffId) {
      state.liffId = liffId
    },
    SET_LINE_DATA (state, lineData) {
      state.lineData = lineData
    },
    SET_ORGANIZATION (state, organization) {
      state.organization = organization
    },
    SET_THEME_CONFIG (state, themeConfig) {
      state.themeConfig = themeConfig
    },
    SET_LINE_OA_ID (state, lineOaId) {
      state.lineOaId = lineOaId
    },
    SET_LIFF_FORCE_FRIEND (state, liffForceFriend) {
      state.liffForceFriend = liffForceFriend
    }
  },
  actions: {
    async init(context, org_code) {
      await axios.get(process.env.VUE_APP_API_BASE_URL + '/' + org_code + '/liff/init').then(response => {
        if (response.data.status === 'success' && response.data.data !== undefined) {
          let data = response.data.data;

          context.commit('SET_LIFF_ID', data.liff_id)
          context.commit('SET_ORGANIZATION', data.organization)
          context.commit('SET_THEME_CONFIG', data.theme_config_customer_bind)
          context.commit('SET_LINE_OA_ID', data.line_oa_id)
          context.commit('SET_LIFF_FORCE_FRIEND', data.liff_force_friend)

          if (data.liff_og_title !== null || data.liff_og_title !== undefined) {
            document.title = data.liff_og_title
          }

          if (data.liff_icon_url !== null || data.liff_icon_url !== undefined) {
            var link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = data.liff_icon_url
          }

        } else {
          throw new Error('初始化失敗');
        }
      }).catch((error) => {
        console.error(error)
        alert('錯誤，請與客服人員聯繫');
        return false;
      })

      await liff.init({
        liffId: context.state.liffId
      }).then(async () => {
        if (! liff.isLoggedIn()) {
          liff.login({
            redirectUri: window.location.href
          })
        } else {
          context.commit('SET_LINE_DATA', {
            profile: await liff.getProfile(),
            accessToken: liff.getAccessToken(),
          })
        }
      }).catch((error) => {
        console.error(error)
        alert('錯誤，請與客服人員聯繫');
      })
    }
  },
};
