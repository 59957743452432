import axios from "axios";
import router from '@/router'
import store from "@/store";

const errorHandler = (status, msg) => {
  console.error(status + ":" + msg)
  const token = store.state.auth.token

  switch (status) {
    case 401:
      if (token) {
        store.dispatch('auth/logout').then(() => {
          alert('登入時效已過期')
          const org_code = store.state.general.currentOrgCode ?? null
          router.push({ name: 'AuthLogin', params: { org_code: org_code }})
        });
      }
      break
    case 403:
      alert('您沒有存取此組織的權限')
      break
  }
};

var instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = store.state.liffAuth.token
    if (token) {
      config.headers.Authorization = "Bearer " + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
);

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { response } = error
    errorHandler(response.status, response.data.error || response.data.message || response.message)
    return Promise.reject(error)
  }
);

export default instance;
