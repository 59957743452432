import store from '@/store'
import router from '@/router'
import Swal from 'sweetalert2'

/**
 * middleware: initGeneralData
 *
 * 事先取得一些必要的資料到 Vuex 中
 */
export default async function initDashboardGeneralData({ next }) {
  await Promise.all([
    new Promise((resolve) => {
      if (! store.state.general.myBranches || ! store.state.general.currentBranch) {
        store.dispatch('general/fetchBranches').then(() => {
          resolve()
        })
      } else {
        resolve()
      }
    }),
    new Promise((resolve) => {
      if (! store.state.general.myOrganizations) {
        store.dispatch('general/fetchMyOrganizations')
          .catch((error) => {
            console.debug(error)
          })
          .finally(() => {
            resolve();
          })
      } else {
        resolve()
      }
    }),
    new Promise((resolve) => {
      store.dispatch('general/fetchDashboardModule').catch(error => console.debug(error)).finally(resolve())
    }),
  ]);

  // 確認目前存取的 Organization 是否在 myOrganizations
  if (store.state.general.organization && store.state.general.myOrganizations) {
    const existed = store.state.general.myOrganizations.find(organization => {
      return store.state.general.organization.id === organization.id
    })

    if (existed === undefined) {
      const user = store.state.auth.user
      const organization = store.state.general.organization

      const result = await Swal.fire({
        title: '錯誤',
        html: `
          <span>已經登入 ${user.current_organization.name}</span><br>
          <span>登入帳號：${user.employee_code} </span><br>
          <span>登入角色：${user.role_name} </span><br>
          <span>無 ${organization.name} 權限</span><br>
          <span>請問是否用新帳號登入 ${organization.name}</span> ?
        `,
        type: 'error',
        confirmButtonText: '新帳號登入',
        showCancelButton: true,
        cancelButtonText: '回到後台',
      });

      if (result.value) {
        await store.dispatch('auth/logout');
        router.push({ name: 'AuthLogin', params: { org_code: organization.code } })
      } else {
        router.push({
          name: 'DashboardHome',
          params: {
            org_code: user.current_organization.code
          }
        })
      }
    }
  }

  if (!store.state.general.myOrganizations) {
    store.dispatch('auth/logout').then(() => {
      const org_code = store.state.general.currentOrgCode ?? null
      router.push({ name: 'AuthLogin', params: { org_code: org_code }})
    });
  }

  return next()
}
