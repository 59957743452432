import Client from './Client'

export default class Order extends Client {
  async index({ status, page }) {
    const response = await this.get('/', { status, page })

    return {
      data: response.data,
      currentPage: response.meta.current_page,
      lastPage: response.meta.last_page,
    }
  }

  async showOrder({ id }) {
    const response = await this.get(`/${id}`)
    return response.data
  }

  async cancelOrder({ id }) {
    await this.delete(`/${id}`)
  }

  async getCard() {
    const { data } = await this.get('card')

    return data
  }
}
