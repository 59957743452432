import Client from './Client'

export default class CoreGroupBuying extends Client {
  async getCoupons(params) {
    const { data } = await this.get('', params)

    return data
  }

  async applyCoupon(coupon) {
    await this.post(`/${coupon.id}/apply`)
  }

  async takeCoupon(code) {
    await this.post('take', { code })
  }

  async redeemCoupon(coupon) {
    await this.post(`/${coupon.id}/redeem`)
  }

  async getCoupon(id) {
    console.log('id', id)
    const { data } = await this.get(`${id}`)
    return data
  }

  async myCodesInBundle(bundleId, params = {}) {
    const { data } = await this.get(`my-codes-in-bundle/${bundleId}`, params)

    return data
  }
}
