import Client from './Client'

export default class Huggies extends Client {
   async getAvailablePoints() {
    const { data } = await this.get('available-points')

    return data
  }

  async listGifts() {
    const { data } = await this.get('gifts')

    return data
  }

  async listRedeemLogs() {
    const { data } = await this.get('redeem-logs')

    return data
  }

  async listPointsLogs(params) {
    return await this.get('points-logs', params)
  }

  async redeemGift(gift) {
    await this.put(`gifts/${gift.id}/_redeem`)
    return true
  }

  async sendSmsVerificationCode(phoneNumber) {
    await this.post(`send-sms-verification-code`, { 'mobile_phone': phoneNumber })
    return true
  }

  async verifyAndUpdateMobileNumber({ mobile_phone, code }) {
    return await this.post(`verify-and-update-mobile-phone`, { mobile_phone, code })
  }
}
