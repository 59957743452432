import Client from '@/apis/liff/Order'
import { state, mutations, getters, actions } from './base'

export default {
  namespaced: true,
  state: state(),
  mutations: mutations(),
  getters: getters(),
  actions: {
    ...actions(Client),
    index (_, { status, page }) {
      return Client.getInstance().index({ status, page })
    },
    showOrder (_, { id }) {
      return Client.getInstance().showOrder({ id })
    },
    cancelOrder (_, { id }) {
      return Client.getInstance().cancelOrder({ id })
    }
  }
}
