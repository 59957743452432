export default class CheckerPipeline {
  constructor(context, customer, permissions) {
    this.context = context
    this.customer = customer
    this.permissions = permissions
    this.checkers = []
  }

  static make(context, customer, permissions) {
    return new CheckerPipeline(context, customer, permissions)
  }

  through(checker) {
    this.checkers.push(...checker)

    return this
  }

  then(onSuccess) {
    for (let i = 0; i < this.checkers.length; i++) {
      const checker = new this.checkers[i](this.context, this.customer, this.permissions)

      if (!checker.check()) {
        return checker.onFailure()
      }
    }

    return onSuccess()
  }
}
