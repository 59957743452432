import Vue from "vue";
import Router from "vue-router";
import routesDashboard from "./routesDashboard";
import routesLiff from "./routesLiff";
import routesRedirect from "./routesRedirect";
import kernel from './middlewares/kernel'

Vue.use(Router);

const routes = [
  ...routesDashboard,
  ...routesLiff,
  ...routesRedirect
];

const router = new Router({
  scrollBehavior: () => ({ y: 0 }),
  mode: "history",
  routes,
});

router.beforeEach(kernel);

export default router
