import Checker from './Checker'

export default class PhoneVerifiedOnly extends Checker {
  check() {
    const phoneVerifiedOnly = this.permissions.includes('phone_verified')

    if (!phoneVerifiedOnly) {
      return true
    }

    return !!this.customer?.phone_verified_at
  }

  onFailure() {
    this.redirect({ name: 'LiffRegisterIndex' })
  }
}

