import axios from 'axios'
import store from '@/store'
import Client from '@/apis/liff/Chatroom'
import { processLiffState } from './helpers'
import VConsole from 'vconsole';

const PATH_PATTERN = '%s/liff/chatrooms%s'
const ROUTE_PORTAL = 'LiffChatroomPortal'
const ROUTE_INDEX = 'LiffChatroomIndex'

function tokenProvider () {
  return store.getters['liffChatroom/token']
}

async function liffInitializer () {
  await store.dispatch('liffChatroom/removeToken')
  await store.dispatch('liffChatroom/init')
}

export default async function liffChatroom({ next, to }) {
  const orgCode = to.params.orgCode
  const baseURL = `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/liff/chatrooms`

  console.debug(`navigated to: ${to.fullPath}`)

  store.commit('liffChatroom/SET_ORG_CODE', orgCode)
  store.commit('liffChatroom/SET_API_BASE_URL', baseURL)

  if (to.query['liff.state']) {
    return processLiffState({ to, next, orgCode, pathPattern: PATH_PATTERN })
  }

  if (to.query['vconsole']) {
    new VConsole({ theme: 'dark' });
  }

  if (to.name === ROUTE_PORTAL) {
    return next({ name: ROUTE_INDEX, params: { orgCode } })
  }

  if (!Client.instance) {
    await Client.init({ axios, baseURL, tokenProvider, liffInitializer })
  }

  if (to.query['as_staff'] == true || to.query['as_staff'] === '1' || to.query['as_staff'] === 'true') {
    await store.dispatch('liffChatroom/setAsStaff', true);
  }

  await store.dispatch('liffChatroom/fetchUser')

  return next()
}
