import Client from './Client'

export default class RedeemCode extends Client {
  async useRedeemCode({ code, event }) {
    let response = {}
    if (event) {
      response = await this.post(`/redeem`, { redeem_code: code, event_code: event })
    } else {
      response = await this.post(`/redeem`, { redeem_code: code })
    }

    return response.data
  }

  async getRedeemEvent({ code }) {
    const response = await this.get('/redeem-event', { event_code: code })
    return response.data
  }
}
