import store from '@/store';

export default function middlewareKernel(to, from, next) {
  store.commit('route/PUSH', to);

  if (to.matched.some(record => record.meta.middleware)) {
    let middleware = to.matched
      .filter((record) => record.meta.middleware)
      .map((record) => record.meta.middleware);
    middleware = [].concat.apply([], middleware)
    const context = {
      from,
      next,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
}

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];

  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    if (parameters.length > 0) {
      context.next(...parameters);
    }

    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}
