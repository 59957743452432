import store from "@/store";

export default async function huggies({ next, to }) {
  const org_code = to.params.org_code;
  try {
    await store.dispatch("liffHuggies/setOrgCode", { orgCode: org_code });
    await store.dispatch("liffHuggies/initLiff");
    await store.dispatch("liffHuggies/fetchMe");
  } catch (error) {
    console.log("error", error);
  }

  let params = new URL(location.href).searchParams;
  if (params.get("liff.state")) {
    const { path, query } = to;

    const liffState = query["liff.state"];

    const newPath = path.substring(0, path.lastIndexOf("/"));

    if (liffState) {
      location.href = `${newPath}${liffState}`;
      return;
    }
  }

  if (params.get("redirect_url")) {
    localStorage.setItem("huggies_redirect_url", params.get("redirect_url"));
    localStorage.setItem("huggies_redirect_text", params.get("redirect_text"));
  }

  return next();
}
